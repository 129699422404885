import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/main.scss"


const NotFound = () => {

	return(
		<Layout>
			<SEO title="NotFound" />
            NotFound


		</Layout>
	)
}

export default NotFound
